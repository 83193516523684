import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { Grid, Typography } from "@material-ui/core"

export const Life = ({amount}) => {
  return <Grid item xs={4}>
    <Typography variant="h6">
    <FontAwesomeIcon icon={faHeart} />
    <span style={{margin:'0 0.5rem'}}>=</span>
    {amount}
    </Typography>
  </Grid>
}