import { TextField, withStyles } from "@material-ui/core";

const CustomTextField = withStyles((theme) => ({
  root: {
    "& input": {
      fontSize: theme.typography.h2.fontSize,
      lineHeight: 1.8,
      padding: theme.spacing(1,3),
      height: "auto",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "calc(108px / 2)",
      },
    },
  },
}))(TextField);

export const Answer = ({ value, onChange, onEnter }) => {
  return (
    <CustomTextField
      autoFocus
      fullWidth
      value={value}
      onChange={onChange}
      variant="outlined"
      inputProps={{
        placeholder: "พิมพ์คำตอบ",
        style: { textAlign: "center" },
      }}
      placeholder="ช่องสำหรับคำตอบ"
      InputLabelProps={{
        style: { textAlign: "center", fontSize: "h1" },
      }}
      onKeyUp={({ keyCode }) => {
        if (keyCode === 13) {
          onEnter();
        }
      }}
    />
  );
};
