import "./App.css";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import HomePage from "./Page/Home";
import PlayPage from "./Page/play2";
import { connectKG } from "@knowgistics/core";
import { firebaseConfig } from "./modules/firebase";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faTrophy } from "@fortawesome/pro-duotone-svg-icons";
import { PageRanking } from "./Page/ranking";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/play" component={PlayPage} />
        <Route path="/ranking" component={PageRanking} />
        <Route path="/" component={HomePage} />
      </Switch>
    </BrowserRouter>
  );
}

export default connectKG({
  firebaseConfig,
  Link,
  profileSecondaryActions: (
    <>
      <ListItem button component={Link} to="/play">
        <ListItemIcon>
          <FontAwesomeIcon icon={faPlay} />
        </ListItemIcon>
        <ListItemText primary="เล่นเกม" />
      </ListItem>
      <ListItem button component={Link} to="/ranking">
        <ListItemIcon>
          <FontAwesomeIcon icon={faTrophy} />
        </ListItemIcon>
        <ListItemText primary="อันดับคะแนน" />
      </ListItem>
    </>
  ),
})(App);
