import { faVolumeMute, faVolumeUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton } from "@material-ui/core";
import React from "react";

function fnBrowserDetect() {
  let userAgent = navigator.userAgent;
  if (userAgent.match(/chrome|chromium|crios/i)) {
    return "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    return "firefox";
  } else if (userAgent.match(/safari/i)) {
    return "safari";
  } else if (userAgent.match(/opr\//i)) {
    return "opera";
  } else if (userAgent.match(/edg/i)) {
    return "edge";
  } else {
    return "No browser detection";
  }
}

const AudioListen = (props) => {
  const [now, setNow] = React.useState(Date.now().toString());
  const [playing, setPlaying] = React.useState(false);
  const audioRef = React.useRef(); //React Referent Audio ..

  const handleOnClick = async () => {
    setPlaying(true);
    switch (fnBrowserDetect()) {
      case "safari":
        return (() => {
          setNow(Date.now().toString());
          audioRef.current.play();
          setTimeout(() => {
            setPlaying(false);
          }, 3000);
        })();
      case "chrome":
        return (() => {
          audioRef.current.play();
          audioRef.current.addEventListener("ended", () => {
            setPlaying(false);
          });
        })();
      default:
        return null;
    }
  };

  return props.text ? (
    <>
      <Box color="primary.main">
        {playing ? (
          <IconButton color="inherit">
            <FontAwesomeIcon size="3x" icon={faVolumeMute} />
          </IconButton>
        ) : (
          <IconButton color="inherit" onClick={handleOnClick}>
            <FontAwesomeIcon size="3x" icon={faVolumeUp} />
          </IconButton>
        )}
      </Box>
      <audio ref={audioRef} autoPlay display="none" key={now+props.text}>
        <source
          src={`https://pi-nest.mek.network/gtts/th/${encodeURI(props.text)}?now=${now}`}
          key={now+props.text}
        />
      </audio>
    </>
  ) : null;
};

export default AudioListen;
