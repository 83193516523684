import {
  Backdrop,
  Box,
  Typography,
  withStyles,
  IconButton,
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { green, red } from "@material-ui/core/colors";

const InnerBox = withStyles((theme) => ({
  root: {
    width: "76vmin",
    height: "76vmin",
    color: "white",
    borderRadius: "38vmin",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}))(Box);

const InnerBoxout = withStyles((theme) => ({
  root: {
    width: "80vmin",
    height: "80vmin",
    backgroundColor: "white",
    borderRadius: "40vmin",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}))(Box);

const EndGame = ({ current, score, color, onReset }) => {
  const getText = () => {
    let endResult = "";
    const rate = Math.round(Math.random() * 3);
    const pos = ["", "สภาพพพพพ !!", "ไม่มีวัวผสม !!", "ป้าพูดได้ไหมมม ??"];

    switch (true) {
      case score <= 19:
        endResult = pos[rate];
        break;
      case score <= 39:
        endResult = "ปังพินาศ !!";
        break;
      case score <= 59:
        endResult = "ละอ่อน !!";
        break;
      case score <= 79:
        endResult = "ฉลาดเนอะ !!";
        break;
      case score <= 99:
        endResult = "ปังปุริเย่ !!";
        break;
      case score === 100:
        endResult = "มึงโกงแน่ ๆ !!";
        break;
      default:
        break;
    }
    return endResult;
  };

  return (
    <div>
      <Backdrop open={true} style={{ zIndex: 1101 }}>
        <InnerBoxout>
          <InnerBox sx={{backgroundColor:score < 59 ? red[700] : green[700]}}>
            <Box mt={4} />
            <Typography variant="h2">{getText()}</Typography>
            <Box mt={2} />
            <Typography variant="h4">คะแนนที่ได้ คือ {score}</Typography>
            {current?.word && (
              <Typography variant="h6">เฉลย {current.word}</Typography>
            )}
            <Box mt={1} />
            {onReset ? (
              <IconButton color="inherit" onClick={onReset}>
                <FontAwesomeIcon size="3x" icon={faHome} />
              </IconButton>
            ) : (
              <IconButton color="inherit" component={Link} to="/">
                <FontAwesomeIcon size="3x" icon={faHome} />
              </IconButton>
            )}
          </InnerBox>
        </InnerBoxout>
      </Backdrop>
    </div>
  );
};

export default EndGame;
