import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Box, Grid, Typography } from "@material-ui/core";

export const Score = ({ score }) => {
  return (
    <Grid item xs={4}>
      <Box textAlign={"right"}>
        <Typography variant="h6">
          <FontAwesomeIcon icon={faStar} />
          <span style={{ margin: "0 0.5rem" }}>=</span>
          {score}
        </Typography>
      </Box>
    </Grid>
  );
};
