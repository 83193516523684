import { Box, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";

export const Hint = ({ answer, word, translate }) => {
  const [hint, setHint] = useState("");

  useEffect(() => {
    let index = [];
    const max = Math.ceil(word.length * 0.2);
    while (index.length < max) {
      const i = Array.from(Array(word.length).keys())[
        Math.ceil(Math.random() * word.length)
      ];
      if (!index.includes(i)) {
        index.push(i);
      }
    }
    setHint(
      word
        .split("")
        .map((t, i) => (index.includes(i) ? t : "-"))
        .join("")
    );
  }, [word]);

  return (
    <Grid item xs={12}>
      <Box textAlign={"center"}>
        Hint: {hint} ({(answer || "").length}/{word.length}) {translate}
      </Box>
    </Grid>
  );
};
