import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/pro-duotone-svg-icons";
import { faAward } from "@fortawesome/pro-duotone-svg-icons";
import { Container, ContentHeader, MainContainer } from "@knowgistics/core";
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../modules/firebase";
import { grey, red, yellow } from "@material-ui/core/colors";
import { Link } from "react-router-dom";

const Award = ({ index }) => {
  switch (index) {
    case 0:
      return (
        <span style={{ color: yellow[700] }}>
          <FontAwesomeIcon size="2x" icon={faAward} />
        </span>
      );
    case 1:
      return (
        <span style={{ color: grey[500] }}>
          <FontAwesomeIcon size="2x" icon={faAward} />
        </span>
      );
    case 2:
      return (
        <span style={{ color: red[200] }}>
          <FontAwesomeIcon size="2x" icon={faAward} />
        </span>
      );
    default:
      return <Typography>{index + 1}</Typography>;
  }
};

export const PageRanking = () => {
  const [state, setState] = useState({
    loading: true,
    lists: [],
  });

  useEffect(() => {
    const q = query(
      collection(db, "spellword"),
      orderBy("score", "desc"),
      limit(10)
    );
    getDocs(q).then((snap) => {
      const lists = snap.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .filter((d) => d.life === 0);
      setState((s) => ({ ...s, lists, loading: false }));
    });
  }, []);

  return (
    <MainContainer loading={state.loading}>
      <Container maxWidth="xs">
        <ContentHeader
          label="Ranking"
          breadcrumbs={[{ label: "Home", to: "/" }]}
        />
        <List>
          <Divider />
          {state.lists.map((list, index) => (
            <ListItem dense divider key={list.id}>
              <ListItemAvatar>
                <Avatar src={list.photoURL} />
              </ListItemAvatar>
              <ListItemText
                primary={list.displayName}
                secondary={`${list.score} คะแนน`}
              />
              <ListItemSecondaryAction>
                <Award index={index} />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Box mt={3} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            size="large"
            startIcon={<FontAwesomeIcon icon={faPlay} />}
            component={Link}
            to="/play"
          >
            เล่นเกม
          </Button>
        </Box>
      </Container>
    </MainContainer>
  );
};
