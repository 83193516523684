export const wordLists = [
  {
    word: "เกม",
    translate:
      "น. การแข่งขันที่มีกติกากำหนด",
  },
  {
    word: "เซ็นชื่อ",
    translate: "ก. ลงชื่อ.",
  },
  {
    word: "เซนติเมตร",
    translate:
      "น. ชื่อมาตราวัดตามวิธีเมตริก มีอัตราเท่ากับ ๑ ใน ๑๐๐ ของ ๑ เมตร, อักษรย่อว่า ซม., (ปาก) เรียกสั้น ๆ ว่า เซ็นต์.",
  },
  {
    word: "เต็นท์",
    translate:
      "น. ที่พักหรือที่อาศัยชั่วคราว ย้ายไปได้ โดยมากทำด้วยผ้าใบขึงกับเสาหรือหลัก.",
  },
  {
    word: "เท่",
    translate: "ว. เอียงน้อย ๆ (มักใช้แก่การสวมหมวก)",
  },
  {
    word: "เทเวศร์",
    translate: "(-เวด) น. เทวดาผู้เป็นใหญ่, หัวหน้าเทวดา",
  },
  {
    word: "เทคนิค",
    translate: "น. ศิลปะหรือกลวิธีเฉพาะวิชานั้น ๆ.",
  },
  {
    word: "เทิด",
    translate: "ก. เชิดชู.",
  },
  {
    word: "เนรมิต",
    translate:
      "(-ระมิด) ก. นิรมิต, สร้างหรือบันดาลด้วยอำนาจ ฤทธิ์ หรืออภินิหารให้บังเกิดเป็นขึ้นมีขึ้นโดยฉับพลัน.",
  },
  {
    word: "เบญจเพส",
    translate: "ว. ยี่สิบห้า เช่น อายุถึงเบญจเพส.",
  },
  {
    word: "เบรก",
    translate: "(เบฺรก) น. เครื่องห้ามล้อ.",
  },
  {
    word: "เปอร์เซ็นต์",
    translate:
      "น. จำนวนที่เทียบเป็นส่วนร้อยหรือร้อยละ, การคิดเทียบเป็นส่วนร้อย เรียกว่า ร้อยละ หรือ ต่อร้อย ใช้เครื่องหมาย % แทน",
  },
  {
    word: "เผ่าพันธุ์",
    translate: "น. เชื้อสาย.",
  },
  {
    word: "เพชฌฆาต",
    translate:
      "(เพ็ดชะคาด) น. เจ้าหน้าที่ประหารชีวิตนักโทษที่ต้องคำพิพากษาถึงที่สุดให้ประหารชีวิต.",
  },
  {
    word: "เพียบพร้อม",
    translate: "ว. เต็มเปี่ยม, ครบทุกอย่าง.",
  },
  {
    word: "เฟซบุ๊ก",
    translate: "เป็นบริการเครือข่ายสังคมสัญชาติอเมริกัน สำนักงานใหญ่อยู่ที่ เมนโลพาร์ก รัฐแคลิฟอร์เนีย เฟซบุ๊กก่อตั้งเมื่อวันพุธที่ 4 กุมภาพันธ์ ค.ศ. 2004 โดยมาร์ก ซักเคอร์เบิร์ก",
  },
  {
    word: "เฟรนช์ฟรายส์",
    translate: "น. เป็นมันฝรั่งหั่นแท่งทอดในน้ำมันท่วมแบบกรอบหรือนุ่มด้านใน โรยเกลือแล้วเสิร์ฟขณะร้อน",
  },
  {
    word: "เล่นพิเรนทร์",
    translate:
      "ก. กระทำสิ่งที่นอกลู่นอกทางหรือนอกรีตนอกรอยเพื่อความสนุกเป็นต้น",
  },
  {
    word: "เลือนราง",
    translate:
      "ว. ไม่ชัดเจน, พอระลึกได้บ้าง",
  },
  {
    word: "เวทมนตร์",
    translate:
      "(เวดมน) น. ถ้อยคำศักดิ์สิทธิ์ที่บริกรรมเพื่อให้สำเร็จความประสงค์",
  },
  {
    word: "เว็บไซต์",
    translate: "น. หน้าเว็บเพจหลายหน้า ซึ่งเชื่อมโยงกันผ่านทางไฮเปอร์ลิงก์ ส่วนใหญ่จัดทำขึ้นเพื่อนำเสนอข้อมูลผ่านคอมพิวเตอร์ โดยถูกจัดเก็บไว้ในเวิลด์ไวด์เว็บ",
  },
  {
    word: "เสกสรร",
    translate:
      "(-สัน) ก. เลือกทำหรือพูดเอาเอง",
  },
  {
    word: "เสน่ห์",
    translate: "(สะเหฺน่) น. ลักษณะที่ชวนให้รัก",
  },
  {
    word: "เสิร์ฟ",
    translate:
      "ก. ยกอาหารหรือเครื่องดื่มมาบริการ",
  },
  {
    word: "เสื้อเชิ้ต",
    translate: "น. เสื้อคอปกชนิดหนึ่ง มีสาบเชื่อมระหว่างปกกับตัวเสื้อ. (อ. shirt).",
  },
  {
    word: "เสื้อกาวน์",
    translate: "น. เสื้อคลุมยาว, สำหรับแพทย์หรือนักวิทยาศาสตร์",
  },
  {
    word: "เหม็นสาบ",
    translate: "กลิ่นเหม็นชนิดหนึ่ง",
  },
  {
    word: "เหลวไหล",
    translate:
      "ว. ไม่เป็นสาระ",
  },
  {
    word: "เอเซีย",
    translate: "เป็นทวีปขนาดใหญ่และมีประชากรมากที่สุดในโลก พื้นที่ส่วนมากตั้งอยู่ในซีกโลกเหนือและซีกโลกตะวันออก ทวีปเอเชียเป็นส่วนหนึ่งของทวีปยูเรเชียรวมกับทวีปยุโรป และเป็นส่วนหนึ่งของทวีปแอฟโฟร-ยูเรเชียร่วมกับยุโรปและแอฟริกา",
  },
  {
    word: "แก๊ง",
    translate: "น. พืดหินหรือโขดหินที่กีดขวางทางนํ้า มักจะมีตามต้นแม่นํ้า.",
  },
  {
    word: "แกงกะหรี่",
    translate: "อาหารประเภทแกงที่ได้รับอิทธิพลจากแกงแบบมัสล่าหรือแกงกุรุหม่าของอินเดียใต้ ซึ่งมีน้ำมากกว่าแกงกุรุหม่าทางอินเดียเหนือ เครื่องเทศหลักที่ใช้ในแกงกะหรี่แบบอินเดีย-ปากีสถาน ได้แก่ ลูกผักชี ยี่หร่า พริกป่นอินเดีย พริกไทยป่น ขมิ้น ลูกกระวานเทศ กานพลู อบเชย นิยมโรยหน้าด้วยใบสำมาหลุย",
  },
  {
    word: "แกงบวด",
    translate:
      "น. ของหวานที่ใช้ผลไม้ เผือก มัน ฟักทอง เป็นต้น ต้มกับนํ้าตาลและกะทิ.",
  },
  {
    word: "แกงมัสมั่น",
    translate: "น. อาหารประเภทแกงที่ได้รับอิทธิพลมาจากอาหารมลายู ชาวไทยมุสลิมเรียกแกงชนิดนี้ว่า ซาละหมั่น แกงมัสมั่นแบบมุสลิมไทยออกรสหวานในขณะที่ตำรับดั้งเดิมของชาวมุสลิมออกรสเค็มมัน ในไทยมีวิธีการทำสองแบบคือ แบบไทย น้ำพริกแกงมี พริกแห้ง ข่า ตะไคร้ หอม กระเทียม ลูกผักชี ยี่หร่า ดอกจันทน์ กานพลู ปรุงรสให้หวานนำ เค็มและอมเปรี้ยว เป็นแกงมีน้ำมากเพื่อรับประทานกับข้าว",
  },
  {
    word: "แท็กซี่",
    translate:
      "น. รถยนต์รับจ้างสาธารณะ โดยสารได้ไม่เกิน ๗ คน. ก. แล่นช้า ๆ ไปตามทางวิ่ง ก่อนนำเครื่องบินขึ้นหรือเมื่อเครื่องบินลงแล้วจะเข้าที่จอด.",
  },
  {
    word: "แบงก์",
    translate: "น. ธนาคาร",
  },
  {
    word: "แผนการ",
    translate: "น. แผนตามที่กะกำหนดไว้.",
  },
  {
    word: "แฟชั่น",
    translate:
      "น. สมัยนิยม, แบบหรือวิธีการที่นิยมกันทั่วไปในชั่วระยะเวลาหนึ่ง.",
  },
  {
    word: "แมงกะพรุน",
    translate:
      "น. ชื่อสัตว์นํ้าไม่มีกระดูกสันหลังหลายชนิดในไฟลัม Cnidaria ลำตัวคล้ายร่ม ลักษณะเป็นวุ้น ปากอยู่ด้านล่าง มีอวัยวะคล้ายหนวดรอบปาก",
  },
  {
    word: "แมลงสาบ",
    translate: "น. (อังกฤษ: Cockroachs) เป็นแมลงที่อยู่ในอันดับ Blattodea หรือ Blattaria จัดเป็นแมลงที่มีวงจรชีวิตไม่สมบูรณ์ คือ ไม่เป็นตัวหนอนและดักแด้ ปัจจุบันเป็นแมลงที่พบกระจายไปแล้วทั่วโลก โดยติดไปกับยานพาหนะต่าง ๆ พบได้ถึงขนาดบนเครื่องบินโดยสาร ถือเป็นสัตว์ที่เป็นพาหะนำโรค",
  },
  {
    word: "แล็บ",
    translate: "น. ห้องทดลอง",
  },
  {
    word: "แสตมป์",
    translate: "(สะแตม) น. ดวงตราไปรษณียากร, ตราไปรษณียากร ก็เรียก",
  },
  {
    word: "โควตา",
    translate: "(โคฺว-) น. การจำกัดจำนวนโดยวางกฎเกณฑ์ไว้.",
  },
  {
    word: "โจทก์จำเลย",
    translate: "[โจด]  (กฎ) น. บุคคลผู้ฟ้องคดีต่อศาล (ป., ส.). , น. บุคคลซึ่งถูกฟ้องต่อศาลแล้ว",
  },
  {
    word: "โจทย์เลข",
    translate: "[โจด] น. คำถามในวิชาคณิตศาสตร์, โดยปริยายใช้หมายถึงสิ่งที่คล้ายคลึงเช่นนั้น",
  },
  {
    word: "โน้ต",
    translate: "น. เครื่องหมายกำหนดเสียงดนตรี.",
  },
  {
    word: "โลกาภิวัตน์",
    translate: "น. การแพร่กระจายไปทั่วโลก",
  },
  {
    word: "ใต้เท้า",
    translate: "ส. คำใช้แทนผู้ที่เราพูดด้วยอย่างเคารพ, เป็นสรรพนามบุรุษที่ ๒.",
  },
  {
    word: "ไต้ก๋ง",
    translate: "น. นายท้ายเรือสำเภาหรือเรือจับปลา.",
  },
  {
    word: "ไต้ฝุ่น",
    translate:
      "น. ชื่อพายุหมุนที่มีกำลังแรงจัด ทำให้มีฝนตกหนักมาก เกิดขึ้นในบริเวณภาคตะวันตกของมหาสมุทรแปซิฟิกและในทะเลจีน มีความเร็วลมใกล้บริเวณศูนย์กลางตั้งแต่ ๖๕ นอต หรือ ๑๒๐ กิโลเมตรต่อชั่วโมงขึ้นไป.",
  },
  {
    word: "ไตรยางศ์",
    translate:
      "น. ๓ ส่วน, ใช้เป็นชื่ออักษร ๓ หมู่ คือ อักษรสูง อักษรกลาง และอักษรตํ่า.",
  },
  {
    word: "ไล่เลี่ย",
    translate: "ว. พอ ๆ กัน, สูสี",
  },
  {
    word: "ไวยากรณ์",
    translate:
      "น. วิชาภาษาว่าด้วยรูปคำและระเบียบในการประกอบรูปคำให้เป็นประโยค.",
  },
  {
    word: "ไอศกรีม",
    translate:
      "น. ของกินทำด้วยนํ้าหวาน กะทิ หรือนม เป็นต้น ทำให้ข้นด้วยความเย็น.",
  },
  {
    word: "กราฟิก",
    translate: "คือการออกแบบสื่อสัญลักษณ์ที่มองเห็นด้วยตา (เป็นทัศนศิลป์อย่างหนึ่ง) ทำหน้าที่เพื่อสื่อความหมาย",
  },
  {
    word: "กะเทย",
    translate:
      "น. คนที่มีอวัยวะเพศทั้งชายและหญิง, คนที่มีจิตใจและกิริยาอาการตรงข้ามกับเพศของตน",
  },
  {
    word: "กะเพรา",
    translate:
      "(-เพฺรา) น. ชื่อไม้ล้มลุกชนิด  Ocimum tenuiflorum L. ในวงศ์ Labiatae กลิ่นฉุน ใช้ปรุงอาหาร พันธุ์ที่กิ่งและก้านใบสีเขียวอมแดงเรียก กะเพราแดง ใช้ทำยาได้.",
  },
  {
    word: "กะโหลก",
    translate:
      "(-โหฺลก) น. กระดูกที่หุ้มมันสมอง, ส่วนแข็งที่หุ้มเนื้อมะพร้าวหรือตาลเป็นต้น เรียกว่า กะโหลกมะพร้าว กะโหลกตาล, ภาชนะที่ทำด้วยกะโหลกมะพร้าวโดยตัดทางตาออกพอเป็นช่องกว้างเพื่อใช้ตักนํ้า",
  },
  {
    word: "กะทันหัน",
    translate: "ว. ทันทีทันใด, ปัจจุบันทันด่วน, จวนแจ.",
  },
  {
    word: "กะบังลม",
    translate:
      "น. แผ่นกั้นประกอบด้วยกล้ามเนื้อ เอ็นกล้ามเนื้อ และแผ่นพังผืด กั้นระหว่างช่องอกกับช่องท้อง ยืดและหดช่วยการหายใจ, คนทั่วไปเรียกบริเวณระหว่างหัวหน่าวกับสะดือ ว่า กะบังลม ด้วย.",
  },
  {
    word: "กะปิ",
    translate:
      "น. ของเค็มทำด้วยเคยกับเกลือโขลกและหมักไว้ ใช้ปรุงอาหาร, เยื่อเคย ก็ว่า.",
  },
  {
    word: "กะพริบ",
    translate:
      "(-พฺริบ) ก. ปิดและเปิดหนังตาโดยเร็ว, โดยปริยายหมายถึงอาการที่คล้ายคลึงเช่นนั้น",
  },
  {
    word: "กังวาน",
    translate: "ว. ก้องอยู่ได้นาน",
  },
  {
    word: "กำมะถัน",
    translate:
      "น. ธาตุสีเหลือง ติดไฟง่าย กลิ่นเหม็นฉุน ใช้ทำยา ทำดินปืน ฯลฯ, สุพรรณถัน ก็ว่า",
  },
  {
    word: "กิจจะลักษณะ",
    translate: "ว. เป็นการเป็นงาน, เป็นเรื่องเป็นราว, เป็นระเบียบเรียบร้อย.",
  },
  {
    word: "กิตติศัพท์",
    translate: "น. เสียงเล่าลือ, เสียงสรรเสริญ, เสียงยกย่อง.",
  },
  {
    word: "ขะมักเขม้น",
    translate:
      "(ขะมักขะเม่น) ก. ตั้งใจทำอย่างรีบเร่งเพื่อให้แล้วเสร็จไป, ก้มหน้าก้มตาทำ, เขม้นขะมัก ก็ว่า.",
  },
  {
    word: "ข้าวเหนียวมูน",
    translate:
      "ว. อ่อนนุ่มพอดี ๆ ไม่สวยและไม่แฉะ",
  },
  {
    word: "ขึ้นฉ่าย",
    translate:
      "น. ชื่อไม้ล้มลุกชนิด  Apium graveolens L. ในวงศ์ Umbelliferae ใบคล้ายผักชีแต่โตกว่า กลิ่นฉุน เป็นพรรณไม้ที่นำเข้ามาปลูกเพื่อเป็นอาหาร.",
  },
  {
    word: "ครองราชย์",
    translate: "ก. ดำรงฐานะเป็นพระมหากษัตริย์",
  },
  {
    word: "คริสตกาล",
    translate: "(คฺริดตะกาน) น. สมัยเมื่อพระเยซูยังมีพระชนม์อยู่.",
  },
  {
    word: "คริสตจักร",
    translate:
      "(คฺริดตะจัก) น. ประชาคมที่เชื่อและรับพระเยซูเป็นพระผู้ไถ่บาป แบ่งเป็น ๓ นิกายใหญ่ ได้แก่ โรมันคาทอลิก โปรเตสแตนต์ และออร์ทอดอกซ์.",
  },
  {
    word: "ครุภัณฑ์",
    translate: "(คะรุพัน) น. ของที่ใช้ทนทาน เช่น ตู้ โต๊ะ เก้าอี้.",
  },
  {
    word: "คฤหาสน์",
    translate: "(คะรึหาด) น. เรือน, โดยมากหมายถึงเรือนขนาดใหญ่และสง่าผ่าเผย.",
  },
  {
    word: "คลิก",
    translate: "ก. ใช้นิ้วกดลงไปปุ่มของเม้าส์แล้วดีดนิ้วออกทันที",
  },
  {
    word: "ค้อน",
    translate: "ก. ติ, ว่าให้สะเทือนใจ.",
  },
  {
    word: "คอลัมน์",
    translate:
      "น. ข้อเขียนในหนังสือพิมพ์หรือนิตยสาร ซึ่งมักเป็นเรื่องแนวเดียวกัน",
  },
  {
    word: "คำนวณ",
    translate: "ก. กะประมาณ, คิดหาผลลัพธ์โดยวิธีเลข.",
  },
  {
    word: "คุกกี้",
    translate:
      "น. ชื่อขนมชนิดหนึ่ง ทำด้วยแป้งสาลี ไข่ เนย น้ำตาล เป็นต้น ทำเป็นชิ้นเล็ก ๆ แล้วอบให้กรอบ.",
  },
  {
    word: "ฆาตกร",
    translate: "น. ผู้ที่ฆ่าคน.",
  },
  {
    word: "ฆาตกรรม",
    translate: "น. การฆ่าคน.",
  },
  {
    word: "จงกรม",
    translate:
      "(-กฺรม) ก. เดินไปมาโดยมีสติกำกับอย่างพระเดินเจริญกรรมฐาน เรียกว่า เดินจงกรม.",
  },
  {
    word: "จลาจล",
    translate: "(จะลาจน) น. ความปั่นป่วนวุ่นวายไม่มีระเบียบ.",
  },
  {
    word: "จักจั่น",
    translate:
      "(จักกะ-) น. ชื่อแมลงในวงศ์ Cicadidae ลำตัวยาวตั้งแต่ ๒-๑๐ เซนติเมตร หัวและอกกว้าง ปีกมี ๒ คู่ เนื้อปีกส่วนใหญ่ใสเหมือนกันทั้ง ๒ คู่ เมื่อเกาะอยู่กับที่ปีกพับเป็นรูปหลังคาคลุมตัว มีปากชนิดเจาะดูด ตาโตเห็นได้ชัด เพศผู้มีอวัยวะทำเสียงอยู่ทางด้านล่างของส่วนท้องได้ยินไปไกล ส่วนใหญ่ลำตัวสีน้ำตาลเข้ม ที่พบบ่อยเป็นชนิด  Dundubia  intermerata Walker.",
  },
  {
    word: "จักรพรรดิ",
    translate:
      "น. พระราชาธิราช, ประมุขของจักรวรรดิ, โบราณเขียนว่า จักรพัตราธิราช ก็มี.",
  },
  {
    word: "จักสาน",
    translate: "น. เรียกเครื่องใช้ที่ทำด้วยไม้ไผ่หรือหวายเป็นต้น",
  },
  {
    word: "จาระไน",
    translate: "ก. พูดชี้แจงอย่างละเอียดลออหรือถี่ถ้วน.",
  },
  {
    word: "จาระบี",
    translate:
      "น. นํ้ามันข้นเหนียวสำหรับหล่อลื่นและกันความสึกหรอของเครื่องจักรเป็นต้น, จระบี ก็ว่า.",
  },
  {
    word: "จำนง",
    translate: "ก. ประสงค์, มุ่งหวัง, ตั้งใจ.",
  },
  {
    word: "จินตนาการ",
    translate: "น. การสร้างภาพขึ้นในจิตใจ.",
  },
  {
    word: "ฉะนั้น",
    translate:
      "ว. ฉันนั้น, เช่นนั้น, อย่างนั้น, ดังนั้น, ดั่งนั้น, เพราะฉะนั้น, เพราะเหตุนั้น.",
  },
  {
    word: "ชโลม",
    translate:
      "(ชะ-) ก. ใช้สิ่งที่เป็นของเหลวลูบไล้ให้เปียก",
  },
  {
    word: "ชอุ่ม",
    translate: "(ชะ-) ว. ชุ่ม, สดชื่น",
  },
  {
    word: "ชะลอ",
    translate:
      "ก. อาการที่ลากเลื่อนสิ่งของที่หนักให้ค่อย ๆ เคลื่อนไปจากที่, พยุงไว้, ประคองไว้, ค่อย ๆ ประคองไป",
  },
  {
    word: "ชีวประวัติ",
    translate: "น. ประวัติชีวิตบุคคล.",
  },
  {
    word: "ซาวเสียง",
    translate:
      "ก. ลองพูดหรือทำอย่างใดอย่างหนึ่งเพื่อฟังความคิดเห็นจากผู้อื่นหรือคนจำนวนมาก, หยั่งเสียง ก็ว่า.",
  },
  {
    word: "ซ่าหริ่ม",
    translate:
      "น. ชื่อขนมอย่างหนึ่ง ทำด้วยแป้งถั่วเขียว ลักษณะเป็นเส้นเล็กและยาว มีหลายสี กินกับนํ้ากะทิผสมนํ้าเชื่อมใส่น้ำแข็ง.",
  },
  {
    word: "ซีเมนต์",
    translate:
      "น. วัสดุใช้ในการก่อสร้างชนิดหนึ่ง ลักษณะเป็นผง เมื่อผสมคลุกกับนํ้าแล้วทิ้งไว้จะเกิดปฏิกิริยาเคมีเป็นผลให้เกิดการก่อตัวและแข็งตัวได้ องค์ประกอบเคมีที่สำคัญของซีเมนต์ คือ แคลเซียมซิลิเกต แคลเซียมอะลูมิเนต และแคลเซียมอะลูมิโนเฟอร์ไรต์ ใช้ประโยชน์เพื่อทำคอนกรีต ปูนฉาบ หรือ ปูนก่อเป็นต้น เรียกว่า ปูนซีเมนต์.",
  },
  {
    word: "ซีรีส์",
    translate: "น. อนุกรม ชุดของสิ่งของ ละครที่แสดงทางโทรทัศน์มีหลายตอนจบเป็นต้น",
  },
  {
    word: "ญาติ",
    translate:
      "(ยาด, ยาติ-, ยาดติ-) น. คนในวงศ์วานที่ยังนับรู้กันได้ทางเชื้อสายฝ่ายพ่อหรือฝ่ายแม่.",
  },
  {
    word: "ฎีกา",
    translate: "น. คำอธิบายขยายความ",
  },
  {
    word: "ดอกไม้จันทน์",
    translate:
      "น. เนื้อไม้จันทน์เป็นต้นที่ไสเป็นแถบบางนำมาประดิษฐ์เป็นช่อขนาดเล็ก ใช้ในการเผาศพ.",
  },
  {
    word: "ดำรง",
    translate:
      "ก. ทรงไว้, ชูไว้, ทำให้คงอยู่",
  },
  {
    word: "ดิจิทัล",
    translate: "ในทฤษฎีข้อมูลหรือระบบข้อมูล เป็นวิธีแทนความหมายของข้อมูลหรือชิ้นงานต่างๆในรูปแบบของตัวเลข โดยเฉพาะเลขฐานสอง ที่ไม่ต่อเนื่องกัน",
  },
  {
    word: "ต้มแซ่บ",
    translate: " (ถิ่น-อีสาน) ว. อร่อย",
  },
  {
    word: "ตราสัง",
    translate:
      "ก. อาการที่สัปเหร่อนำผ้าขาวห่อศพและใช้ด้ายดิบมัดศพเป็น ๕ เปลาะ.",
  },
  {
    word: "ตะกร้า",
    translate:
      "(-กฺร้า) น. ภาชนะสานโปร่งสำหรับใส่สิ่งของ มีรูปต่าง ๆ มีหูหิ้วหรือไม่มีก็ได้.",
  },
  {
    word: "ตรรก",
    translate: "(ตักกะ) น. ความตรึก, ความคิดอย่างใช้เหตุผล.",
  },
  {
    word: "ตำรับ",
    translate:
      "(-หฺรับ) น. ตำราที่กำหนดไว้เป็นเฉพาะแต่ละเรื่องละราย",
  },
  {
    word: "ถั่วพู",
    translate:
      "น. ชื่อถั่วชนิด  Psophocarpus  tetragonolobus (L.) DC. ในวงศ์ Leguminosae ฝักมีครีบตามยาว ๔ ครีบ ฝักอ่อนกินได้.",
  },
  {
    word: "ทแยง",
    translate: "(ทะ-) ว. เฉียง.",
  },
  {
    word: "ทยอย",
    translate:
      "(ทะ-) ว. เคลื่อนตามกันไปไม่ขาดระยะ",
  },
  {
    word: "ทรงกลด",
    translate: "ว. มีแสงสีรุ้งเป็นวงกลมล้อมรอบดวงอาทิตย์หรือดวงจันทร์",
  },
  {
    word: "ทระนง",
    translate: "(ทอระ-) ก. ทะนง.",
  },
  {
    word: "ทลาย",
    translate:
      "(ทะ-) ก. อาการที่สิ่งซึ่งเป็นกลุ่มก้อนแตกหักหรือพังกระจัดกระจาย",
  },
  {
    word: "ทศกัณฐ์",
    translate: "น. “ผู้มีสิบคอ” คือ ท้าวราพณ์ในเรื่องรามเกียรติ์.",
  },
  {
    word: "ทะเลสาบ",
    translate:
      "น. ห้วงนํ้าใหญ่ที่มีแผ่นดินล้อมรอบ มี ๒ ชนิด คือ ทะเลสาบนํ้าจืด และทะเลสาบนํ้าเค็ม.",
  },
  {
    word: "ทะนุถนอม",
    translate: "ก. คอยระวังรักษา, คอยประคับประคอง.",
  },
  {
    word: "ทูต",
    translate:
      "น. ผู้นำข้อความไปแจ้งทั้ง ๒ ฝ่าย, ผู้รับใช้ไปเจรจาแทน, ผู้สื่อสาร, ผู้ที่ได้รับมอบหมายให้เป็นผู้แทนไปยังต่างประเทศ เพื่อเจรจาหรือเจริญสัมพันธไมตรีเป็นทางราชการ.",
  },
  {
    word: "ทูนหัว",
    translate: "น. คำพูดแสดงความรักใคร่หรือยกย่อง",
  },
  {
    word: "ธนบัตร",
    translate:
      "(ทะนะบัด, ทนนะบัด) น. บัตรที่ออกใช้เป็นเงินตรา ซึ่งใช้ชำระหนี้ได้ตามกฎหมายโดยไม่จำกัดจำนวน.",
  },
  {
    word: "ธัญพืช",
    translate:
      "น. พืชล้มลุกหลายชนิดหลายสกุล มักหมายถึงพืชในวงศ์ Gramineae เช่น ข้าว ข้าวสาลี ข้าวโพด ให้เมล็ดเป็นอาหารหลัก.",
  },
  {
    word: "ธุรกิจ",
    translate:
      "น. การงานประจำเกี่ยวกับอาชีพค้าขาย หรือกิจการอย่างอื่นที่สำคัญและที่ไม่ใช่ราชการ",
  },
  {
    word: "นวัตกรรม",
    translate:
      "น. การกระทำหรือสิ่งที่ทำขึ้นใหม่หรือแปลกจากเดิมซึ่งอาจจะเป็นความคิด วิธีการ หรืออุปกรณ์ เป็นต้น.",
  },
  {
    word: "นอต",
    translate:
      "น. หน่วยแสดงความเร็วของสิ่งใดสิ่งหนึ่งตามระบบการเดินเรือ มีค่าเท่ากับ ๑ ไมล์ทะเลต่อชั่วโมง หรือ ๑.๘๕๒ กิโลเมตรต่อชั่วโมง",
  },
  {
    word: "นะคะ",
    translate: "ว. คำลงท้ายที่ผู้หญิงใช้ต่อจากคำถามหรือคำแสดงความสงสัยเพื่อแสดงความสุภาพ",
  },
  {
    word: "นัยน์ตา",
    translate: "น. ดวงตา.",
  },
  {
    word: "น้ำมันก๊าด",
    translate: "น. นํ้ามันชนิดหนึ่งใช้จุดไฟตะเกียงเป็นต้น.",
  },
  {
    word: "นิเวศวิทยา",
    translate:
      "(นิเวดสะวิดทะยา, นิเวดวิดทะยา) น. วิชาที่ศึกษาเกี่ยวกับความสัมพันธ์ระหว่างสิ่งมีชีวิตกับถิ่นที่อยู่และสิ่งแวดล้อม",
  },
  {
    word: "บรรทุก",
    translate:
      "(บัน-) ก. วางลง ใส่ลง หรือบรรจุในยานพาหนะเป็นต้นเพื่อขนย้ายไปทีละมาก ๆ, ประทุก ก็ใช้",
  },
  {
    word: "บล็อก",
    translate: "น. แม่พิมพ์ทำด้วยแผ่นโลหะหรือไม้ ใช้ในกิจการพิมพ์.",
  },
  {
    word: "บอระเพ็ด",
    translate:
      "น. ชื่อไม้เถาชนิด Tinospora crispa (L.) Miers ex Hook.f. et Thomson ในวงศ์ Menispermaceae เถาเป็นตุ่ม รสขม ใช้ทำยาได้.",
  },
  {
    word: "บันเทิง",
    translate: "ก. เบิกบาน, รื่นเริง",
  },
  {
    word: "บันได",
    translate:
      "น. สิ่งที่ทำเป็นขั้น ๆ สำหรับก้าวขึ้นลง, กระได ก็ว่า, โดยปริยายหมายถึง บุคคลหรือสิ่งที่อาศัยใช้ไต่เต้าขึ้นไปสู่ฐานะหรือตำแหน่งที่สูงขึ้นไป.",
  },
  {
    word: "บันลือ",
    translate: "ก. เปล่งเสียงดังก้อง",
  },
  {
    word: "บาดทะยัก",
    translate:
      "น. โรคที่เกิดจากตัวเชื้อโรคบาดทะยัก (Clostridium tetani) เข้าสู่แผล ทำให้มีอาการกระตุก และชักแข็ง หลังแอ่น โดยมากถึงตาย.",
  },
  {
    word: "บาตร",
    translate: "(บาด) น. ภาชนะชนิดหนึ่งสำหรับภิกษุสามเณรใช้รับอาหารบิณฑบาต.",
  },
  {
    word: "บำเหน็จ",
    translate: "น. รางวัล, ค่าเหนื่อย, ค่าความชอบเป็นพิเศษ",
  },
  {
    word: "บิณฑบาต",
    translate: "น. อาหาร (ใช้แก่พระภิกษุสามเณร)",
  },
  {
    word: "บุคลิกภาพ",
    translate: "น. สภาพนิสัยจำเพาะคน.",
  },
  {
    word: "ปฐมนิเทศ",
    translate:
      "(ปะถมมะ-, ปะถม-) น. การแนะนำชี้แนวเพื่อการศึกษาและการทำงานในเบื้องต้น.",
  },
  {
    word: "ปรมาณู",
    translate:
      "(ปะระ-, ปอระ-) น. ส่วนของสารที่มีขนาดเล็กที่สุดจนไม่สามารถจะแยกย่อยได้อีกด้วยวิธีเคมี.",
  },
  {
    word: "ประกาศิต",
    translate: "น. คำสั่งเด็ดขาด, คำพูดที่ศักดิ์สิทธิ์.",
  },
  {
    word: "ประจัญบาน",
    translate: "ก. รบอย่างตะลุมบอน.",
  },
  {
    word: "ประปา",
    translate:
      "น. นํ้าที่เกรอะกรองให้สะอาดปราศจากเชื้อโรคแล้วจ่ายไปให้ประชาชนบริโภคใช้สอย เรียกว่า นํ้าประปา, เรียกรัฐวิสาหกิจซึ่งมีหน้าที่จัดทำและจำหน่ายนํ้าประปา ว่า การประปา, เรียกสิ่งอื่น ๆ ที่เกี่ยวกับการนี้",
  },
  {
    word: "ปรากฏ",
    translate: "(ปฺรากด) ก. สำแดงออกมาให้เห็น.",
  },
  {
    word: "ปลากะพง",
    translate: "น. ชื่อปลาหลายชนิดในหลายวงศ์ที่มีก้านครีบเป็นกระดูกแข็ง ขนาดยาวได้ถึง ๒ เมตร ลำตัวหนา แบนข้างเล็กน้อย หัวโตลาดลงมาจากด้านหลัง ตาค่อนข้างโต ปากกว้าง",
  },
  {
    word: "ปะทะ",
    translate:
      "ก. โดนกัน, กระทบกัน",
  },
  {
    word: "ปิกนิก",
    translate:
      "น. การพากันไปเที่ยวชั่วระยะเวลาสั้น ๆ และมีของกินไปเลี้ยงกันด้วย.",
  },
  {
    word: "ผลลัพธ์",
    translate:
      "น. จำนวนที่ได้จากการคำนวณ",
  },
  {
    word: "ผัดไทย",
    translate:
      "น. อาหารคาวอย่างหนึ่ง ใช้ก๋วยเตี๋ยวเส้นเล็กผัดกับเครื่องปรุงมีน้ำมะขามเปียก เต้าหู้เหลือง หัวไช้โป๊ กุ้งแห้งหรือกุ้งสด ใบกุยช่าย ถั่วงอก เป็นต้น.",
  },
  {
    word: "ผัดวันประกันพรุ่ง",
    translate: "ก. ขอเลื่อนเวลาออกไปครั้งแล้วครั้งเล่า.",
  },
  {
    word: "ผาสุก",
    translate: "น. ความสำราญ, ความสบาย.",
  },
  {
    word: "ผู้เยาว์",
    translate: "น. บุคคลผู้ยังไม่บรรลุนิติภาวะ.",
  },
  {
    word: "ผูกพัน",
    translate: "ก. มีความเป็นห่วงกังวลเพราะรักใคร่เป็นต้น",
  },
  {
    word: "ฝรั่งเศส",
    translate:
      "น. ชาวยุโรป มีประเทศตั้งอยู่ทิศตะวันตกภาคพื้นยุโรป, ชื่อภาษาของชนชาตินั้น.",
  },
  {
    word: "พยัคฆ์",
    translate: "[พะยักคะ-] น. เสือโคร่ง. (ป. พฺยคฺฆ, วฺยคฺฆ; ส. วฺยาฆฺร)",
  },
  {
    word: "พยาบาท",
    translate:
      "(พะยาบาด) น. การผูกใจเจ็บคิดจะแก้แค้น, การคิดปองร้าย, ในคำว่า ผูกพยาบาท.",
  },
  {
    word: "พรรณนา",
    translate:
      "(พันนะ-) ก. กล่าวเป็นเรื่องเป็นราวอย่างละเอียดให้ผู้ฟังนึกเห็นเป็นภาพ.",
  },
  {
    word: "พรหมจรรย์",
    translate: "น. การศึกษาปรมัตถ์, การศึกษาพระเวท",
  },
  {
    word: "พลศึกษา",
    translate:
      "(พะละ-) น. การศึกษาที่จะนำไปสู่ความเจริญงอกงามและพัฒนาการทางร่างกาย.",
  },
  {
    word: "พลิ้ว",
    translate: "ก. บิด, เบี้ยว",
  },
  {
    word: "พะแนง",
    translate: "น. แกงคั่วไก่หรือเนื้อเป็นต้น นํ้าแกงข้น.",
  },
  {
    word: "พะยอม",
    translate:
      "น. ชื่อไม้ต้นขนาดใหญ่ชนิด  Shorea roxburghii G. Don ในวงศ์ Dipterocarpaceae ดอกสีขาว กลิ่นหอม เนื้อไม้ใช้ในการก่อสร้าง.",
  },
  {
    word: "พังทลาย",
    translate: "[ทะ-] ก. อาการที่สิ่งซึ่งเป็นกลุ่มก้อนแตกหักหรือพังกระจัดกระจาย",
  },
  {
    word: "พัสดุ",
    translate:
      "(พัดสะ-) น. สิ่งของต่าง ๆ, เครื่องใช้ไม้สอย, ที่ดิน, บ้านเรือน.",
  },
  {
    word: "พากย์หนัง",
    translate: "ก. พูดตามบทพากย์ภาพยนตร์, พากย์ ก็ว่า.",
  },
  {
    word: "พาณิชย์",
    translate: "[พานิดชะยะ-] น. การค้าขาย (ส. วาณิชฺย; ป. วาณิชฺช)",
  },
  {
    word: "พิพิธภัณฑ์",
    translate:
      "(พิพิดทะพัน, -พันทะสะถาน) น. สถานที่เก็บรวบรวมและแสดงสิ่งต่าง ๆ ที่มีความสำคัญด้านวัฒนธรรมหรือด้านวิทยาศาสตร์ โดยมีความมุ่งหมายเพื่อให้เป็นประโยชน์ต่อการศึกษา และก่อให้เกิดความเพลิดเพลินใจ.",
  },
  {
    word: "พิสดาร",
    translate:
      "(พิดสะดาน) ว. กว้างขวาง, ละเอียดลออ (ใช้แก่เนื้อความ)",
  },
  {
    word: "พิสมัย",
    translate: "(พิดสะไหฺม) น. ความรัก, ความปลื้มใจ, ความชื่นชม.",
  },
  {
    word: "พุทธชาด",
    translate:
      "(พุดทะ-) น. ชื่อไม้เถาชนิด  Jasminum auriculatum Vahl ในวงศ์ Oleaceae ดอกสีขาวกลิ่นหอม, บุหงาประหงัน ก็เรียก.",
  },
  {
    word: "ฟิล์ม",
    translate:
      "(ฟิม) น. แผ่นวัตถุบางประเภทพลาสติกชนิดหนึ่ง ฉาบผิวด้วยสารเคมีที่ไวต่อแสง ใช้สำหรับถ่ายรูป ถ่ายภาพยนตร์.",
  },
  {
    word: "ภาพยนตร์",
    translate:
      "(พาบพะ-) น. ภาพฉายด้วยเครื่องทำให้เห็นเป็นภาพเคลื่อนไหวได้, หนังฉาย.",
  },
  {
    word: "ภูตผีปีศาจ",
    translate: "น. ผี",
  },
  {
    word: "มงกุฎ",
    translate:
      "น. เครื่องสวมพระเศียรพระมหากษัตริย์ เศียรพระพุทธรูปทรงเครื่องหรือเทวรูปเป็นต้น มีลักษณะต่าง ๆ กัน โดยปริยายใช้เรียกเครื่องประดับศีรษะที่มีลักษณะเช่นนั้น",
  },
  {
    word: "มรณภาพ",
    translate: "(มอระนะพาบ) น. ความตาย.",
  },
  {
    word: "มหรสพ",
    translate: "(มะหอระสบ) น. การเล่นรื่นเริงมีโขนละครเป็นต้น.",
  },
  {
    word: "มัคคุเทศก์",
    translate: "น. ผู้นำทาง, ผู้ชี้ทาง, ผู้บอกทาง",
  },
  {
    word: "มังสวิรัติ",
    translate:
      "น. การงดเว้นกินเนื้อสัตว์, เรียกอาหารที่ไม่มีเนื้อสัตว์ มีแต่พืชผัก ว่า อาหารมังสวิรัติ.",
  },
  {
    word: "มัณฑนศิลป์",
    translate: "น. ศิลปะการออกแบบและตกแต่งผลิตกรรมหรืองานช่างต่าง ๆ.",
  },
  {
    word: "มัธยัสถ์",
    translate: "(มัดทะยัด) ก. ใช้จ่ายอย่างประหยัด.",
  },
  {
    word: "มาตรฐาน",
    translate:
      "(มาดตฺระ-) น. สิ่งที่ถือเอาเป็นเกณฑ์ที่รับรองกันทั่วไป เช่น เวลามาตรฐานกรีนิช, สิ่งที่ถือเอาเป็นเกณฑ์สำหรับเทียบกำหนด ทั้งในด้านปริมาณและคุณภาพ",
  },
  {
    word: "มุกตลก",
    translate: "(-ตะหฺลก) น. วิธีทำให้ขบขัน.",
  },
  {
    word: "ย่อมเยา",
    translate: "ว. มีราคาถูก, มีราคาพอสมควร.",
  },
  {
    word: "ยานัตถุ์",
    translate:
      "น. ผงละเอียดที่ปรุงขึ้นจากใบยาสูบหรือวัตถุใด ๆ สำหรับนัดโดยเป่าหรือสูดเข้าจมูก",
  },
  {
    word: "ระบบนิเวศ",
    translate: "คือกลุ่มอินทรีย์ (พืช สัตว์และจุลินทรีย์) ร่วมกับองค์ประกอบอชีวนะของสิ่งแวดล้อมของพวกมัน (เช่น อากาศ น้ำและดินอนินทรีย์) ซึ่งมีปฏิสัมพันธ์กันเป็นระบบ",
  },
  {
    word: "รังควาน",
    translate: "ก. รบกวนทำให้รำคาญหรือเดือดร้อน",
  },
  {
    word: "รังสี",
    translate:
      "น. รังสีแม่เหล็กไฟฟ้าซึ่งมีย่านของการแผ่รังสีที่มีช่วงคลื่นประมาณระหว่าง ๗.๘ x ๑๐–๗ เมตร กับ ๑ มิลลิเมตร, อินฟราเรด ก็เรียก.",
  },
  {
    word: "รัญจวน",
    translate:
      "ก. ปั่นป่วนใจ",
  },
  {
    word: "ฤทธิ์",
    translate: "(ริด) น. อำนาจศักดิ์สิทธิ์, แรงอำนาจ,",
  },
  {
    word: "ลมปราณ",
    translate: "น. ลมหายใจ",
  },
  {
    word: "ลมหวน",
    translate: "น. ลมที่พัดกลับไปกลับมาขณะฝนตก.",
  },
  {
    word: "ล็อกเกต",
    translate:
      "น. เครื่องประดับทำเป็นรูปตลับเล็ก ๆ หรือกรอบรูป มีห่วงสำหรับคล้องสายสร้อยห้อยคอหรือกลัดติดเสื้อ.",
  },
  {
    word: "ละเอียดลออ",
    translate:
      "ว. ถี่ถ้วน, รอบคอบ",
  },
  {
    word: "ลาดตะเวน",
    translate: "ก. เที่ยวตรวจไปทั่วเพื่อป้องกันข้าศึกศัตรูเป็นต้น",
  },
  {
    word: "ลายเซ็น",
    translate: "น. ลายมือชื่อ.",
  },
  {
    word: "ลิปสติก",
    translate:
      "น. เครื่องสำอางชนิดหนึ่งสำหรับทาริมฝีปากให้เป็นสีต่าง ๆ มักเป็นสีแดงหรือชมพู โดยมากทำเป็นแท่งเล็ก ๆ.",
  },
  {
    word: "ลิฟต์",
    translate:
      "น. ห้องเล็ก ๆ แขวนอยู่กับลวดสลิง เคลื่อนที่ด้วยพลังไฟฟ้า สำหรับนำคนหรือของขึ้นลงในอาคารสูง ๆ.",
  },
  {
    word: "ลูกนิมิต",
    translate:
      "น. ลูกที่ทำกลม ๆ ประมาณเท่าบาตร มักทำด้วยหิน ใช้ฝังเป็นเครื่องหมายเขตอุโบสถ.",
  },
  {
    word: "ลูกบาศก์",
    translate:
      "น. ลูกเหลี่ยมมี ๖ หน้า แต่ละหน้าเป็นสี่เหลี่ยมจัตุรัส, เรียกลูกเต๋าที่ใช้ทอดในการเล่นสกา.",
  },
  {
    word: "วงศ์วาน",
    translate:
      "น. ลูกหลานเหลนในตระกูล, มักใช้เข้าคู่กับคำ ว่านเครือ เป็น วงศ์วานว่านเครือ.",
  },
  {
    word: "วัคซีน",
    translate:
      "น. ผลิตผลที่ประกอบด้วยเชื้อไวรัสหรือแบคทีเรียที่ถูกฆ่าหรือทำให้มีฤทธิ์อ่อนแรงจนไม่เป็นอันตราย สำหรับฉีดเข้าสู่ร่างกายเพื่อกระตุ้นให้เกิดภูมิคุ้มกันโรคซึ่งเกิดจากเชื้อนั้น ๆ.",
  },
  {
    word: "วัณโรค",
    translate:
      "น. โรคชนิดหนึ่งเกิดที่ปอดเป็นต้น ทำให้ร่างกายทรุดโทรมเสื่อมไปตามลำดับ, โบราณเรียกวัณโรคปอด ว่า ฝีในท้อง.",
  },
  {
    word: "วิ่งเปี้ยว",
    translate:
      "น. การเล่นวิ่งแข่งโดยแบ่งผู้เล่นออกเป็น ๒ ฝ่าย แต่ละฝ่ายส่งคนวิ่งอ้อมหลักของฝ่ายตรงข้ามทีละคน โดยวิธีรับช่วงไม้หรือผ้าต่อ ๆ กันไป ฝ่ายที่วิ่งเร็วกว่า ใช้ไม้หรือผ้าที่ถืออยู่ตีฝ่ายตรงข้ามได้ เป็นฝ่ายชนะ.",
  },
  {
    word: "วีดิทัศน์",
    translate: "เป็นศัพท์บัญญัติตรงกับคำภาษาอังกฤษว่า video (อ่านว่า วิ-ดี-โอ). ศาสตราจารย์ ดร. คุณบรรจบ พันธุเมธา เป็นผู้คิดคำนี้ขึ้น",
  },
  {
    word: "สไบ",
    translate: "(สะ-) น. ผ้าแถบ, ผ้าคาดอกผู้หญิง.",
  },
  {
    word: "สถิต",
    translate:
      "(สะถิด) ก. อยู่, ยืนอยู่, ตั้งอยู่, (ใช้เป็นคำยกย่องแก่สิ่งหรือบุคคลที่อยู่ในฐานะสูง)",
  },
  {
    word: "สปาเกตตี",
    translate:
      "น. ชื่ออาหารอิตาลีชนิดหนึ่ง ทำจากแป้งสาลี เป็นเส้นกลมตันคล้ายเส้นขนมจีนแต่โตกว่าเล็กน้อย.",
  },
  {
    word: "สมดุล",
    translate: "[สะมะดุน, สมดุน] ว. เสมอกัน, เท่ากัน. (อ. equilibrium)",
  },
  {
    word: "สร้างสรรค์",
    translate:
      "ก. สร้างให้มีให้เป็นขึ้น (มักใช้ทางนามธรรม)",
  },
  {
    word: "สังเกต",
    translate: "ก. กำหนดไว้, หมายไว้",
  },
  {
    word: "สัณฐาน",
    translate:
      "น. รูปทรง, ลักษณะ",
  },
  {
    word: "สับปะรด",
    translate:
      "น. ชื่อไม้ล้มลุกชนิด Ananas comosus (L.) Merr. ในวงศ์ Bromeliaceae ไม่มีลำต้นปรากฏบนพื้นดิน ใบเป็นกาบยาวให้ใยใช้ทำสิ่งทอ ขอบใบมีหนาม ผลมีตาโดยรอบ กินได้ รสเปรี้ยว ๆ หวาน ๆ",
  },
  {
    word: "สัมภาษณ์",
    translate:
      "ก. สนทนาหรือสอบถามเพื่อนำเรื่องราวไปเผยแพร่ทางหนังสือพิมพ์หรือวิทยุกระจายเสียงเป็นต้น",
  },
  {
    word: "สัมมนา",
    translate:
      "น. การประชุมแบบหนึ่ง ซึ่งมีวัตถุประสงค์เพื่อแลกเปลี่ยนความรู้ ความคิดเห็น และหาข้อสรุปหรือข้อเสนอแนะในเรื่องใดเรื่องหนึ่ง ผลสรุปที่ได้ถือว่าเป็นเพียงข้อเสนอแนะ ผู้เกี่ยวข้องจะนำไปปฏิบัติตามหรือไม่ก็ได้",
  },
  {
    word: "สาบสูญ",
    translate: "ก. สูญหายไปอย่างไม่ทิ้งร่องรอย.",
  },
  {
    word: "สาปแช่ง",
    translate:
      "ก. กล่าวมุ่งร้ายให้ผู้อื่นเป็นอันตรายอย่างร้ายแรง",
  },
  {
    word: "สามเส้า",
    translate: "ก. อาการที่นกเขาขันคูเป็น ๓ จังหวะ, สามกุก ก็ว่า. (พจน. ๒๔๙๓)",
  },
  {
    word: "สายสิญจน์",
    translate:
      "น. ด้ายดิบสีขาวที่นำมาจับทบเป็น ๓ เส้น หรือ ๙ เส้น ใช้ในพิธีทางศาสนา เช่นสำหรับพระถือในเวลาสวดมนต์ หรือพราหมณ์ใช้ในพระราชพิธีบางอย่าง.",
  },
  {
    word: "สารประโยชน์",
    translate:
      "(สาระ-) น. ประโยชน์ที่เป็นแก่นสาร",
  },
  {
    word: "สิริมงคล",
    translate:
      "(กัน-) น. ฉัตร ๕ ชั้นและ ๗ ชั้น สำรับหนึ่งมี ๓ องค์ คือ พระเสมาธิปัต พระฉัตรชัย พระเกาวพ่ายหรือพระเกาวพ่าห์ ทำด้วยผ้าขาวลงยันต์เส้นเขียนทอง เป็นเครื่องสูงอันเป็นสิริมงคลยิ่งสำหรับพระมหากษัตริย์ ใช้กางเชิญนำพระราชยานเวลาเสด็จพระราชดำเนินโดยกระบวนพยุหยาตราใหญ่ และใช้เข้าพิธีคชกรรมนำช้างสำคัญขึ้นจากแพเข้าสู่โรงสมโภช หรือใช้ผูกเสาพระแท่นมณฑลในพระราชพิธีใหญ่, เขียนเป็น กรรม์ภิรมย์ กันภิรมย์ หรือ กันพิรุณ ก็มี.",
  },
  {
    word: "หงส์",
    translate:
      "(หง) น. ชื่อนกจำพวกเป็ดขนาดใหญ่หลายชนิด วงศ์ย่อย Cygninae ในวงศ์ Anatidae คอยาว เป็นนกที่นำเข้ามาในประเทศไทย มีถิ่นกำเนิดในทวีปยุโรป อเมริกา และตอนเหนือของทวีปเอเชีย",
  },
  {
    word: "หยากไย่",
    translate: "น. ใยแมงมุมที่ติดค้างอยู่ในที่ต่าง ๆ, หยักไย่ ก็ว่า.",
  },
  {
    word: "หลงใหล",
    translate: "ก. คลั่งไคล้ในสิ่งใดสิ่งหนึ่ง",
  },
  {
    word: "อเนกประสงค์",
    translate: "(อะเหฺนกปฺระสง) ว. ใช้ประโยชน์ได้หลายอย่าง แล้วแต่ความต้องการ.",
  },
  {
    word: "อนุญาต",
    translate: "ก. ยินยอม, ยอมให้, ตกลง.",
  },
  {
    word: "อนุมัติ",
    translate: "(-มัด) ก. ให้อำนาจกระทำการตามระเบียบที่กำหนดไว้.",
  },
  {
    word: "อัญชัน",
    translate:
      "น. ชื่อไม้เถาชนิด  Clitoria ternatea L. ในวงศ์ Leguminosae ดอกสีครามแก่ ขาว และม่วงอ่อน.",
  },
  {
    word: "อัปเดต",
    translate: "ทำให้ทันสมัย",
  },
  {
    word: "อานิสงส์",
    translate: "น. ผลแห่งกุศลกรรม, ผลบุญ",
  },
  {
    word: "อิเล็กทรอนิกส์",
    translate:
      "(-ทฺรอ-) น. วิทยาศาสตร์กายภาพแขนงหนึ่งที่นำมาประยุกต์ใช้กับการศึกษาวงจรไฟฟ้าที่ใช้สารกึ่งตัวนำและอุปกรณ์อื่น ๆ ซึ่งควบคุมการเคลื่อนที่ของอิเล็กตรอนได้.",
  },
  {
    word: "อิริยาบถ",
    translate:
      "น. อาการที่ร่างกายอยู่ในท่าใดท่าหนึ่ง เช่น วิ่ง กระโดด เดิน ยืน นั่ง นอน, ถ้าตามมหาสติปัฏฐานสูตร กำหนดไว้ ๔ อย่าง คือ เดิน ยืน นั่ง นอน.",
  },
  {
    word: "อีเมล",
    translate: "ข้อมูลที่ใช้เพื่อระบุตัวตนบนเครือข่าย เปรียบเสมือนกับเป็นที่อยู่ที่ใช้รับและส่งจดหมายอิเล็กทรอนิกส์",
  },
  {
    word: "ฮ่อยจ๊อ",
    translate:
      "น. ชื่ออาหารแบบจีนชนิดหนึ่ง ใช้เนื้อปู มันหมูแข็งต้มสุก ผสมกับแป้งมันและเครื่องปรุงรส บดหรือโขลกจนเหนียวแล้วห่อด้วยฟองเต้าหู้เป็นท่อน กลม ๆ ยาว ๆ มัดเป็นเปลาะ ๆ นึ่งแล้วทอดให้สุก กินกับน้ำจิ้ม.",
  },
];
