import { db } from "../../modules/firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  runTransaction,
} from "firebase/firestore";

export class GameSave {
  user = null;

  constructor(user) {
    this.user = user;
  }

  async getLastSave() {
    if (this.user?.uid) {
      const ref = doc(db, "spellword", this.user.uid);
      const snap = await getDoc(ref);
      return snap.data() || null;
    } else {
      return null;
    }
  }

  async save(state) {
    if (this.user?.uid) {
      const colRef = collection(db, "spellword");
      const ref = doc(db, "spellword", this.user.uid);
      if (state.life === 0) {
        await deleteDoc(ref);
        await addDoc(colRef, {
          ...state,
          displayName: this.user.displayName,
          photoURL: this.user.photoURL,
        });
      } else {
        runTransaction(db, async (transaction) => {
          const saveDoc = await transaction.get(ref);
          if (!saveDoc.exists()) {
            await transaction.set(ref, { ...state, user: this.user.uid });
          } else {
            await transaction.update(ref, { ...state });
          }
        });
      }
    }
  }
}
