import React from "react";
import { Link } from "react-router-dom";
import { Container, MainContainer } from "@knowgistics/core";

import "../../App.css";

import logo from "../../assest/Homepage-login.png";
import { styled } from "@material-ui/core";

const ShowImage = styled((props) => (
  <Link to="/play" {...props}>
    <img src={logo} alt="cover" />
  </Link>
))({
  position: "relative",
  display: "block",
  paddingTop: "calc(100% * 3 / 4)",
  "& img": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});

const HomePage = () => {
  return (
    <MainContainer>
      <Container maxWidth="md">
        <ShowImage />
      </Container>
    </MainContainer>
  );
};

export default HomePage;
