import { Button } from "@material-ui/core";

export const CheckAnswer = ({ onClick, disabled }) => {
  return (
    <Button
      color="primary"
      variant="contained"
      size="large"
      disabled={disabled}
      onClick={onClick}
    >
      ยืนยันคำตอบ
    </Button>
  );
};
