import { createContext, useContext } from "react";

export const defaultState = {
  life: 3,
  step: 0,
  round: 1,
  score: 0,
  skip: 3,
  words: [],
  current: {
    word: "",
    translate: "",
  },
};

export const PlayContext = createContext({
  state: defaultState,
  setState: () => {},
});

export const usePlay = () => useContext(PlayContext);
