import { Container, KGContext, MainContainer } from "@knowgistics/core";
import { Box, Button, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { wordLists } from "../../assest/wordLists_wt";
import AudioListen from "../../Component/AudioListen";
import { defaultState, PlayContext } from "./ctx";
import { Hint } from "./hint";
import { Life } from "./life";
import { Score } from "./score";
import { Answer } from "./answer";
import correctAudio from "../../assest/CorrectSound.mp3";
import wrongAudio from "../../assest/WrongSound.mp3";
import { CheckAnswer } from "./check";
import EndGame from "../../Component/EndGame";
import { useContext } from "react";
import { GameSave } from "./save";
import React from "react";

const getNextWord = (current = []) => {
  const left = wordLists.filter((w) => !current.includes(w.word));
  return left[Math.round(Math.random() * left.length)];
};

const PagePlay = React.memo(() => {
  const {
    user,
    state: [{ userFetched }],
  } = useContext(KGContext);
  const [state, setState] = useState({ ...defaultState });
  const [answer, setAnswer] = useState("");
  const [control, setControl] = useState(new GameSave(null));

  const [sound] = useState({
    c: new Audio(correctAudio),
    w: new Audio(wrongAudio),
  });

  const store = {
    state,
    setState,
  };

  const handleChange = ({ target: { value } }) => setAnswer(value);
  const handleSkip = () => {
    if (state.skip > 0) {
      setState((s) => {
        let words = s.words.concat(s.current.word);
        const current = getNextWord(words);
        control.save({ ...s, skip: s.skip - 1, words, current });
        return { ...s, skip: s.skip - 1, words, current };
      });
    }
  };
  const handleCheck = () => {
    if (answer) {
      if (answer === state.current.word) {
        sound.c.play();
        setState((s) => {
          let words = s.words.concat(answer);
          const current = getNextWord(words);
          const score = s.score + 1;
          const life = score % 10 === 0 ? s.life + 1 : s.life;
          control.save({ ...s, score, words, life, current });
          return { ...s, score, words, life, current };
        });
        setAnswer("");
      } else {
        sound.w.play();
        setState((s) => {
          control.save({ ...s, life: s.life - 1 });
          return { ...s, life: s.life - 1 };
        });
      }
    }
  };
  const handleReset = () => {
    setState({ ...defaultState, current: getNextWord([]) });
    setAnswer("");
  };

  useEffect(() => {
    sound.c.volume = 0.2;
    sound.w.volume = 0.2;
  }, [sound]);

  useEffect(() => {
    if (userFetched) {
      if (user) {
        const control = new GameSave(user);
        setControl(control);
        control.getLastSave().then((data) => {
          if (data) {
            setState({ ...data });
          } else {
            setState((s) => ({ ...s, current: getNextWord([]) }));
          }
        });
      } else {
        setState((s) => ({ ...s, current: getNextWord([]) }));
        setControl(new GameSave(null));
      }
    }
  }, [userFetched, user]);

  return (
    <PlayContext.Provider value={store}>
      <MainContainer loading={!userFetched}>
        {state.life === 0 ? (
          <EndGame
            current={state.current}
            score={state.score}
            onReset={handleReset}
          />
        ) : (
          <Container maxWidth="sm">
            <Grid container alignItems="center" spacing={2}>
              <Life amount={state.life} />
              <Grid item xs={4}>
                <Box display="flex" justifyContent="center">
                <AudioListen text={state.current.word} />
                </Box>
              </Grid>
              <Score score={state.score} />
              <Grid item xs={12}>
                <Answer
                  value={answer}
                  onChange={handleChange}
                  onEnter={handleCheck}
                />
              </Grid>
              <Grid item xs={12}>
                <Box textAlign="center">
                  <CheckAnswer
                    onClick={handleCheck}
                    disabled={!Boolean(answer)}
                  />
                  &nbsp;
                  {state.skip > 0 && (
                    <Button
                      variant="contained"
                      size="large"
                      onClick={handleSkip}
                    >
                      ข้าม ({state.skip})
                    </Button>
                  )}
                </Box>
              </Grid>
              <Hint
                answer={answer}
                word={state.current.word}
                translate={state.current.translate}
              />
            </Grid>
          </Container>
        )}
      </MainContainer>
    </PlayContext.Provider>
  );
});
export default PagePlay;
